$purple0: #dfe2ff;
$purple1: #d0d8ff;
$purple2: #7990ff;
$purple3: #8286e3;
$purple4: #6c7dfe;
$purple5: #5368ff;
$purple6: #3042bf;
$purple7: #4a4dbc;

// $red: rgba(255, 83, 128, 1);
$red: #b30030;
$orange: #ffa46b;
$green: rgba(40, 223, 111, 1);
$blue: #4a4dbb;
$lightBlue: #e9efff;
$mediumBlue: #2b2563;
$darkBlue: #110f27;
$desatDarkBlue: #334354;
$error: #b00020;
// $contrastBackground: #B4002A;

$offWhite: rgb(243, 241, 245);
$almostWhite2: rgb(120, 127, 161);
$lightGrey: rgb(189, 189, 189);
$grey: rgb(170, 170, 170);
$darkGrey: rgb(110, 110, 110);
$inactiveColor: #eee;

$grey0: rgb(248, 248, 248);
$grey1: rgb(243, 242, 243);
$grey2: rgb(218, 218, 218);
$grey3: rgba(0, 0, 0, 0.26);
$grey4: rgba(62, 81, 89, 1);

$lightText: #545f82;
$darkText: #334354;
$darkerText: #17262a;
$darkestText: #1f1b41;

$almostBlack: #213333;

$shadow-color: rgba(49, 58, 68, 0.2);
$dark-shadow-color: rgba(0, 0, 0, 0.15);
$darker-shadow-color: rgba(0, 0, 0, 0.2);

// colors from new rebranding
$cs-grey: #efe6ff;
$cs-dark-grey: #2c2c2c;
$cs-white: #ffffff;
$cs-pink: #dc5b78;
$cs-red: #a30543;

$cs-blue-1: #1f1b4a;
$cs-blue-2: #3b3183;
$cs-blue-3: #5259ff;
$cs-blue-4: #7c96fc;
$cs-blue-5: #b7c6fc;

$cs-orange-1: #e56813;
$cs-orange-2: #ef8137;
$cs-orange-3: #ff964a;
$cs-orange-4: #ffc099;
$cs-orange-5: #ffdccc;

$cs-green-1: #036038;
$cs-green-2: #08955c;
$cs-green-3: #05be73;
$cs-green-4: #0edd84;
$cs-green-5: #2bfc9d;
