@import "@cs/styles";

.h1,
.h2,
.p {
  color: $mediumBlue;
  font-size: 45px;
  margin: 0;
}

.h1 {
  font-size: 45px;

  @include media(">1700px") {
    font-size: 65px;
  }

  @include media("<=desktop") {
    font-size: 38px;
  }
}

.h2 {
  font-size: 40px;

  @include media(">1700px") {
    font-size: 55px;
  }

  @include media("<=desktop") {
    font-size: 30px;
  }
}

.p {
  font-size: 22px;
  line-height: 1.3;

  @include media(">1700px") {
    font-size: 30px;
  }

  @include media("<=tablet") {
    font-size: 20px;
  }
}
