@import "@cs/screens/courses/CoursesScreen.module.scss";

.button {
  padding: 14px 2rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  background: linear-gradient(90deg, #110f27 0%, $mediumBlue 100%);

  @include media(">1700px") {
    font-size: 25px;
  }

  &:hover {
    background: linear-gradient(90deg, $mediumBlue 0%, $mediumBlue 100%);
    color: #fff;
    transform: scale(1.04);
  }
}

.light {
  @extend .button;

  background: #fff;
  color: $mediumBlue;

  &:hover {
    color: $mediumBlue;
    background: #fff;
  }
}

.underline {
  @extend .button;
  padding: 0;
  background: transparent;

  &:hover {
    text-decoration: underline;
    background: transparent;
  }
}

.progress {
  background: transparent;
  position: relative;
  z-index: 0;

  @for $i from 0 through 101 {
    &-#{$i} {
      @extend .progress;

      &:after {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: $i * 1%;
        height: 100%;
        background: linear-gradient(90deg, #110f27 0%, $mediumBlue 100%);
        border-radius: 5px;
      }

      &:before {
        content: "";
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: #b9b9b9;
        border-radius: 5px;
      }
    }
  }
}

.completed {
  background: linear-gradient(90deg, #2dc788 0%, #299d6d 100%);

  &:hover {
    background: linear-gradient(90deg, #2dc788 0%, #2dc788 100%);
  }
}

.disabled {
  background: grey !important;
  cursor: default;

  &:hover {
    transform: none;
  }
}
